import { render, staticRenderFns } from "./Technology_readiness_level.vue?vue&type=template&id=44089a77&"
import script from "./Technology_readiness_level.vue?vue&type=script&lang=js&"
export * from "./Technology_readiness_level.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports