<template>
  <div>
    <b-row>
      <b-col cols="12">
        <route-buttons />
      </b-col>
      <b-col cols="12">
        <b-card>
          <technology-graphic />
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { BRow, BCol, BCard } from 'bootstrap-vue'
import technologyGraphic from '@/views/Dealers/Reports/components/technology_readiness/Technology_graphic.vue'
import routeButtons from '@/views/Dealers/Reports/components/Route_buttons.vue'

export default {
  name: 'TechnologyReadinessLevel',
  components: {
    BRow,
    BCol,
    BCard,
    technologyGraphic,
    routeButtons,
  },
  created() {
    this.getStartupReports()
    this.getTechnologyReadiness()
  },
  methods: {
    getTechnologyReadiness() {
      this.$store.dispatch('dealerReportTechnologyReadiness/technologyReadiness', this.$route.params.id)
    },
    getStartupReports() {
      this.$store.dispatch('dealerReportStartupReport/getReports', this.$route.params.id)
    },
  },
}
</script>
